<template>
  <SpotlightCard
    v-if="shouldShow"
    class="w-full group p-2 rounded-[--radius] [--radius:theme(borderRadius.lg)] overflow-hidden"
    from="#60a5fa"
    to="#cbd5e1"
    :size="150"
  >
    <div class="absolute inset-px rounded-[calc(var(--radius)-1px)] bg-white">
      <div class="flex justify-center w-full h-full">
        <SpotlightCard
          class="w-full p-2 rounded-[--radius] [--radius:theme(borderRadius.lg)] opacity-20"
          from="#60a5fa"
          :size="150"
        />
      </div>
    </div>
    <div class="relative">
      <div class="flex justify-center w-full h-full">
        <Icon
          name="heroicons:sparkles-solid"
          class="text-blue-500 w-8 h-8 mt-2"
        />
      </div>
      <p
        class="text-gray-500 text-center text-xs mt-4"
        v-text="upgradePlanDescription"
      />
      <v-button
        color="light-blue"
        class="w-full block mt-4 border border-transparent group-hover:border-blue-500"
        size="small"
        @click.prevent="subscriptionModalStore.openModal()"
      >
        Upgrade to {{ upgradePlanName }}
      </v-button>
    </div>
  </SpotlightCard>
</template>

<script setup>
const subscriptionModalStore = useSubscriptionModalStore()
const authStore = useAuthStore()
const workspacesStore = useWorkspacesStore()

const user = computed(() => {
  return authStore.user
})
const workspace = computed(() => {
  return workspacesStore.getCurrent
})

const upgradePlanName = computed(() => {
  return workspace.value?.is_pro ? 'Team' : 'Pro'
})

const upgradePlanDescription = computed(() => {
  return workspace.value?.is_pro ? 'You are currently on the Pro plan. Upgrade to collaborate and use powerful features like editable submissions.' : 'You are currently on the free plan. Upgrade to use all features.'
})

const shouldShow = computed(() => {
  return user.value && authStore.userOnboarded && workspace.value && !workspace.value.is_enterprise
})
</script>
