<template>
  <div
    v-if="show"
    class="bg-yellow-100 group relative dark:bg-yellow-900 -mx-5 px-5 py-2 font-semibold border-b flex items-center"
  >
    <div class="pr-2 group-hover:animate-bounce-slow">
      ⚠️
    </div>
    <div class="text-orange-600 dark:text-orange-400 text-xs">
      Notion API issues may temporarily impact NoteForms.
    </div>
    <a
      class="absolute inset-0"
      target="_blank"
      href="https://status.notion.so/"
    />
  </div>
</template>

<script setup>
const show = ref(false)

onMounted(() => {
  show.value = false
  useNoteFormsApi('/notion/apistatus').then((data) => {
    if (data.status === false)
      show.value = true
  })
})
</script>
