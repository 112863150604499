<template>
  <div
    id="sidebar"
    class="hidden md:block w-[220px] border-r bg-white shrink-0 pb-10"
  >
    <div
      class="sticky"
      :class="{
        'top-2': !hasStickyNavbar,
        'top-[calc(1.25rem+67px)]': hasStickyNavbar
      }"
    >
      <notion-status-banner class="px-5" />

      <div
        v-for="(section, sectionName) in sectionsList"
        :key="sectionName"
        class="mt-5 px-3"
      >
        <p class="text-gray-400 uppercase text-xs mb-2 flex items-center px-2">
          {{ sectionName }}
        </p>
        <ul class="text-sm">
          <li
            v-for="(sectionItem, sectionItemName) in section"
            :key="sectionItemName"
            class="mt-1 flex items-center relative hover:cursor hover:no-underline p-2 rounded-lg"
            :class="[{'text-blue-600 font-semibold bg-blue-50':sectionItem.active, 'text-gray-600 hover:bg-blue-50/50':!sectionItem.active}, sectionItem.textClass]"
          >
            <Icon
              :class="[{'text-blue-600':sectionItem.active, 'text-gray-400':!sectionItem.active}, sectionItem.iconClass]"
              class="w-5 h-5 ml-1 mr-3"
              :name="sectionItem.icon"
            />
            <span class="block truncate">
              {{ sectionItemName }}
            </span>
            <NuxtLink
              v-if="sectionItem.route"
              v-track.sidebar_click="{section: sectionItemName}"
              class="absolute inset-0"
              :to="sectionItem.route"
            />
            <a
              v-else-if="sectionItem.href"
              v-track.sidebar_click="{section: sectionItemName}"
              class="absolute inset-0"
              target="_blank"
              :href="sectionItem.href"
            />
            <div
              v-else-if="sectionItem.action"
              v-track.sidebar_click="{section: sectionItemName}"
              class="absolute inset-0 cursor-pointer"
              @click="sectionItem.action"
            />
          </li>
        </ul>
      </div>
      <div class="px-3 mt-5">
        <UpgradeCard />
      </div>
    </div>
    <notion-connect-modal
      :show="showNotionConnectModal"
      mode="database"
      @success="showNotionConnectModal=false"
      @close="showNotionConnectModal=false"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue'
import NotionStatusBanner from '~/components/notion/NotionStatusBanner.vue'
import NotionConnectModal from '~/components/notion/NotionConnectModal.vue'

const crisp = useCrisp()
const route = useRoute()
const authStore = useAuthStore()
const workspacesStore = useWorkspacesStore()

const hasStickyNavbar = computed(() => route.meta.stickyNavbar)

const user = computed(() => authStore.user)
const myTemplates = ref([])

const showNotionConnectModal = ref(false)

const workspace = computed(() => {
  return workspacesStore.getCurrent
})

const sectionsList = computed(() => {
  const sections = {
    Main: {
      'Forms': {
        icon: 'heroicons:rectangle-stack',
        route: { name: 'home' }
      },
      'Database Views': {
        icon: 'heroicons:view-columns',
        route: { name: 'views' }
      }
    },
    'Resources':
      {
        'Share a Notion DB': {
          icon: 'heroicons:share',
          action: () => {
            showNotionConnectModal.value = true
          }
        },
        'Help Center':
          {
            icon: 'heroicons:lifebuoy',
            href: 'https://help.noteforms.com'
          },
        'What\'s new':
          {
            icon: 'heroicons:newspaper',
            action:
              () => {
                if (process.server) return
                window.Featurebase('manually_open_changelog_popup')
              }
          }
      },
    'Contact': {
      'Send us a message':
        {
          icon: 'heroicons:chat-bubble-left-ellipsis',
          action:
            () => {
              crisp.openAndShowChat()
            }
        },
      'Request a feature':
        {
          icon: 'heroicons:light-bulb',
          action:
            () => {
              if (process.server) return
              window.postMessage({
                target: 'FeaturebaseWidget',
                data: { action: 'openFeedbackWidget' }
              })
            }
        }
    }
  }

  // Add Views
  if (user.value && !authStore.userOnboarded) {
    delete sections.Main['Database Views']
    delete sections.Resources['What\'s new']
    delete sections.Contact['Request a feature']
  }

  // Add templates
  if (user.value && myTemplates && myTemplates.length > 0) {
    sections.Main['My Templates'] = {
      icon: 'heroicons:document-duplicate',
      route: { name: 'my-templates' }
    }
  }

  // Set property active_route to true if it is active
  for (const sectionName in sections) {
    for (const sectionItemName in sections[sectionName]) {
      sections[sectionName][sectionItemName].active = isActive(sections[sectionName][sectionItemName].route)
    }
  }

  return sections
})

const isActive = (secRoute) => {
  const routeName = secRoute?.name || null
  if (routeName === 'forms-slug-show-share' || routeName === 'views-slug-show-share') {
    return secRoute?.params?.slug === route.params?.slug
  }
  return route.name === routeName
}
</script>
