<template>
  <modal
    :show="show"
    :backdrop-blur="true"
    max-width="md"
    @close="$emit('close')"
  >
    <div id="notion-connect">
      <template v-if="mode === 'first-connection'">
        <h2 class="text-center">
          Connect your
          <span
            class="text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-blue-600"
          >Notion Workspace</span>
        </h2>
        <p class="text-gray-500 text-center mt-5">
          To finalize your registration, you need to connect your Notion
          workspace.
          <span class="font-semibold">Make sure to select one or more Notion pages</span>.
        </p>
        <div class="text-center mt-5 animate-bounce-slow">
          <LoginWithNotion
            text="Connect to Notion"
            @success="$emit('success')"
          />
        </div>
      </template>
      <template v-else-if="mode === 'workspace'">
        <h2 class="text-center">
          Connect your
          <span
            class="text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-blue-600"
          >Notion Workspace</span>
        </h2>
        <p class="text-gray-500 text-center mt-5">
          Connect your Notion workspace and start collecting submissions.
          <span class="font-semibold">Make sure to select one or more Notion pages</span>.
        </p>
        <div class="text-center mt-5 animate-bounce-slow">
          <LoginWithNotion
            text="Connect to Notion"
            @success="$emit('success')"
          />
        </div>
      </template>
      <template v-else-if="mode === 'database'">
        <h2 class="text-center">
          Share your
          <span
            class="text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-blue-600"
          >Notion Database</span>
        </h2>
        <p class="text-gray-500 text-center mt-5">
          Share your Notion database and start collecting submissions.
          <span class="font-semibold">Make sure to select the select the right database(s)</span>. You might need to search for it using the search bar.
        </p>
        <div class="text-center mt-5 animate-bounce-slow">
          <LoginWithNotion
            text="Share Notion Database"
            @success="$emit('success')"
          />
        </div>
      </template>
      <template v-else-if="mode === 'page'">
        <h2 class="text-center">
          Share your
          <span
            class="text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-blue-600"
          >Notion Page</span>
        </h2>
        <p class="text-gray-500 text-center mt-5">
          Share your Notion page to create a form and start collecting
          submissions.
          <span class="font-semibold">Make sure to select the select the right page(s)</span>. You might need to search for it using the search bar.
        </p>
        <div class="text-center mt-5 animate-bounce-slow">
          <LoginWithNotion
            text="Share Notion Database"
            @success="$emit('success')"
          />
        </div>
      </template>

      <video
        class="rounded-lg mx-auto mt-10 ring-1 ring-gray-200 shadow-lg shadow-blue-600/10 w-full"
        autoplay
        loop
        muted
      >
        <source
          src="/img/pages/onboarding/videos/notion-connect.mp4"
          type="video/mp4"
        >
        This browser does not display the video tag.
      </video>

      <p
        v-if="showDataSafety"
        class="text-gray-500 text-xs text-center mt-5 p-4 border bg-gray-50 rounded-md"
      >
        <a
          class="underline text-inherit"
          href="#"
          @click.prevent="
            crisp.openHelpdeskArticle('is-my-data-safe-with-notionforms-eh6zkh')
          "
        >Your data stays in Notion</a>. We don't store form submissions.
        <a
          class="underline text-inherit"
          href="#"
          @click.prevent="openChat"
        >Contact us</a>
        if you have any questions.
      </p>
    </div>
  </modal>
</template>

<script>
import LoginWithNotion from '~/components/global/LoginWithNotion.vue'

export default {
  name: 'NotionConnectModal',
  components: { LoginWithNotion },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    showDataSafety: {
      type: Boolean,
      default: false,
      required: false,
    },
    mode: {
      type: String,
      required: false,
      default: 'workspace',
    },
  },
  emits: ['close', 'success'],
  setup() {
    return {
      crisp: useCrisp(),
    }
  },
  data() {
    return {}
  },

  computed: {},

  watch: {},

  mounted() {},

  methods: {},
}
</script>
